import { Box, Heading, Image, Stack, Text } from "@biblioteksentralen/react";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

interface Metadata {
  image?: string;
  title?: string;
  host?: string;
  description?: string;
}

export function SOMEPreview() {
  const [metadata, setMetadata] = useState<Metadata | undefined>();
  const pathname = usePathname();

  useEffect(() => {
    const updateMetadata = () => setMetadata(getMetdata());
    updateMetadata();
    const interval = setInterval(updateMetadata, 5000);
    return () => clearInterval(interval);
  }, [pathname]);

  return (
    <Stack
      maxW="35rem"
      marginY="2rem"
      backgroundColor="white"
      boxShadow="0 .5rem 1rem #8884"
      borderRadius="md"
      padding="1rem"
      justifyContent="space-between"
    >
      <Image borderRadius="md" objectFit="cover" sx={{ aspectRatio: 1.9 / 1 }} src={metadata?.image} alt="" />
      <Box>
        <Text fontWeight={600}>{metadata?.host}</Text>
        <Heading as="h3" fontSize="md" color="blue.600">
          {metadata?.title}
        </Heading>
        <Text noOfLines={2}>{metadata?.description}</Text>
      </Box>
    </Stack>
  );
}

// Henter ut data på denne måten fordi vi prøver å simulere hvordan Facebook/google etc henter ut metadata fra sidene våre
function getMetdata(): Metadata {
  const title = document.querySelector("title")?.innerText; // henter data fra <title>Min tittel</title>
  // @ts-expect-error
  const description = document.querySelector('meta[name="description"]')?.content; // henter data fra <meta name="description" content="Min beskrivelse"/>
  // @ts-expect-error
  const image = document.querySelector('meta[property="og:image"]')?.content; // henter data fra <meta property="og:image" content="https://myimage.jpg" />
  // @ts-expect-error
  const canonical = document.querySelector('link[rel="canonical"]')?.href; // henter data fra <link rel="canonical" href="https://gjeldendeUrl" />

  return { description, image, title, host: getHost(canonical) };
}

function getHost(url?: string) {
  try {
    return new URL(url || "").host;
  } catch {
    return "";
  }
}
