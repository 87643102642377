import { BiblioteksentralenProvider } from "@biblioteksentralen/react";
import { storeFeatureFlags } from "@libry-content/common";
import { validateLocaleOrFallbackToDefault } from "@libry-content/localization";
import { Analytics } from "@vercel/analytics/react";
import { AppProps } from "next/app";
import { ReactNode, useState } from "react";
import Testenvironmentbanner from "../components/TestEnvironmentBanner";
import { EditInSanityContextProvider } from "../components/editInSanity/EditInSanityContext";
import { PreviewContextProvider } from "../components/preview/previewContext";
import { PlausibleSetup } from "../utils/analytics/Plausible";
import { useLogDevInfo } from "../utils/logDevInfo";
// eslint-disable-next-line no-restricted-imports
import { NextIntlClientProvider } from "next-intl";
import ErrorBoundary from "../components/errorPages/ErrorBoundary";
import { StudioSOMEPreview } from "../components/preview/StudioSOMEPreview";
import { customTheme } from "../utils/customTheme";
import { commonNextIntlConfig } from "../i18n";

function Providers(props: { children: ReactNode } & Pick<Props, "pageProps" | "router">) {
  const locale = validateLocaleOrFallbackToDefault(props.router.query.locale);

  return (
    <NextIntlClientProvider locale={locale} messages={props.pageProps.messages} {...commonNextIntlConfig}>
      <PreviewContextProvider>
        <BiblioteksentralenProvider customTheme={customTheme}>
          <EditInSanityContextProvider>{props.children}</EditInSanityContextProvider>
        </BiblioteksentralenProvider>
      </PreviewContextProvider>
    </NextIntlClientProvider>
  );
}

type Props = AppProps;

function App({ Component, pageProps, router }: Props) {
  // storeFeatureFlags should run only once but before other useEffect hooks; useMount and useEffect are too late.
  // useLayoutEffect works but gives an error when running on server, so we use an initial value function with useState.
  useState(() => storeFeatureFlags());

  useLogDevInfo();

  return (
    <ErrorBoundary boundaryName="App" isFullPage>
      <Providers pageProps={pageProps} router={router}>
        <PlausibleSetup />
        <Testenvironmentbanner />
        <Component {...pageProps} />
        <StudioSOMEPreview />
      </Providers>
      <Analytics />
    </ErrorBoundary>
  );
}

export default App;
