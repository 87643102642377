import { sanityConfig } from "@libry-content/common";
import { createClient } from "next-sanity";

// Don't include token here, or client side fetches will include drafts
export const sanityClient = createClient({
  ...sanityConfig,
  useCdn: process.env.NODE_ENV === "production",
});

// Sanity uses localStorage for authtoken when logging in with some browsers (ie. Firefox and safari), while for Chrome Sanity uses cookies
const getSanityAuthTokenFromLocalStorage = (): string | undefined => {
  if (typeof window === "undefined" || !localStorage) return undefined;

  const localStorageToken = localStorage.getItem(`__studio_auth_token_${sanityConfig.projectId}`);

  if (!localStorageToken) return undefined;

  try {
    return JSON.parse(localStorageToken)?.token;
  } catch (err) {
    console.error("Could not parse Sanity auth token from localStorage:", err);
    return undefined;
  }
};

// Client with credentials is used for preview mode
export const clientWithCredidentials = sanityClient.withConfig({
  token: getSanityAuthTokenFromLocalStorage(), // Needed for users that are logged in with token in local storage (ie. in Firefox)
  withCredentials: true, // Needed for users that are logged in with cookie (ie. in Chrome)
});
