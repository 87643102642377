import { isDevelopment } from "@biblioteksentralen/utils";
import { Role } from "@sanity/types";
import { useParams, usePathname } from "next/navigation";
import Script from "next/script";

import {
  isBibsentEmail,
  isE2eTestEmail,
  isGlobalAdmin,
  isRediaEmail,
  useIsInsideAnIframe,
} from "@libry-content/common";
import { useSanityAuth } from "../../components/sanityAuth/useSanityAuth";
import { isProductionDataset } from "../currentDataset";

// This is a temporary heuristic-ish solution to get some idea of the real usage until we get an improved analytics setup
export const getDataDomains = (
  topDomain: string,
  isAuthenticated: boolean,
  email?: string,
  roles?: Role[],
  siteDomain?: string
): string[] => {
  if (isBibsentEmail(email) || isRediaEmail(email) || isGlobalAdmin({ roles })) {
    // Intern trafikk fra Biblioteksentralen og Redia
    return [`intern.${topDomain}`];
  }
  if (isAuthenticated) {
    // Innloggede brukere
    return [`editor.${topDomain}`];
  }
  if (siteDomain) {
    // Logger både til eget dashboard for biblioteket og til felles dashboard for alle bibliotek
    // https://plausible.io/docs/plausible-script#is-there-a-roll-up-view
    return [siteDomain, `felles.${topDomain}`];
  }

  return [topDomain];
};

const isJestTest = process.env.NODE_ENV === "test";

export function PlausibleSetup() {
  const query = useParams();
  const pathname = usePathname();
  const siteDomain = query?.site as string | undefined;
  const isSplashPage = !siteDomain && pathname === "/";
  const { loading, error, email, roles, isAuthenticated } = useSanityAuth();
  const isInsideAnIframe = useIsInsideAnIframe();

  // Don't mount until we know we are not in an iframe (forexample in studio preview)
  if (isInsideAnIframe.loading || isInsideAnIframe.isInside) return null;
  // Don't mount until we are ready to check the user
  if (loading || error) return null;
  // We don't send metrics to plausible for certain scenarios
  if (isE2eTestEmail(email) || isJestTest) return null;

  const topDomain = getPlausibleDomain(isSplashPage);
  const dataDomains = getDataDomains(topDomain, isAuthenticated, email, roles, siteDomain);

  return (
    <Script defer data-domain={dataDomains.join(",")} src="https://plausible.io/js/plausible.local.outbound-links.js" />
  );
}

export function getPlausibleDomain(isSplashPage?: boolean): string {
  const splashPagePrefix = isSplashPage ? "splash." : "";

  if (isProductionDataset && !isDevelopment()) return `${splashPagePrefix}bibliotek.io`;

  if (isDevelopment()) return `${splashPagePrefix}bibliotek-local.io`;

  return `${splashPagePrefix}bibliotek-test.io`;
}

type ExtraData = Record<string, string | number | boolean | undefined>;

// https://plausible.io/docs/custom-event-goals
function logToPlausible(eventName: string, extraData?: ExtraData) {
  isDevelopment() && console.log("📈", eventName, extraData);

  try {
    // @ts-expect-error
    typeof plausible !== "undefined" && plausible(eventName, extraData ? { props: extraData } : undefined);
  } catch (e) {
    console.error(e);
  }
}

// Ved å bruke disse samler vi alle plausible events under samme event-name. Det gjør det lettere å vise de på plausbile-dashboardet
export const logEvent = (label: string, extraData: ExtraData = {}) => logToPlausible("Event", { label, ...extraData });
export const logClick = (label: string, extraData: ExtraData = {}) => logToPlausible("Click", { label, ...extraData });
export const logAdminClick = (label: string, extraData: ExtraData = {}) =>
  logToPlausible("AdminClick", { label, ...extraData });
