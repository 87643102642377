import {
  FieldValue,
  LanguageCode,
  LocalizedField,
  validateLocaleOrFallbackToDefault,
  translateSanity,
} from "@libry-content/localization";
// eslint-disable-next-line no-restricted-imports -- we restrict other files to import from this one
import { useLocale, useTranslations } from "next-intl";
import { useCallback } from "react";

export type TranslateSanityContent = <T = FieldValue>(localizedObject?: LocalizedField<T>) => T | undefined;

export interface Translate {
  t: (key: string, variables?: Record<string, unknown>) => string; // Wasn't able to use type from next-intl
  ts: TranslateSanityContent;
  lang: LanguageCode;
}

export type TranslateMicroText = Translate["t"];

export const useTranslation = (): Translate => {
  const t = useTranslations();
  const locale = useLocale();

  const lang = validateLocaleOrFallbackToDefault(locale);

  const ts: Translate["ts"] = useCallback((localizedObject) => translateSanity(localizedObject, lang), [lang]);

  // Next-intl uses dots as namespace-separators, and it's not possible to configure next-intl to use something else.
  // This helper function removes dots on the fly so we can still use keys with dots in frontend-code and in poe-editor. See README for more info.
  // @ts-ignore
  // eslint-disable-next-line no-restricted-syntax
  const tUtenPunktum: Translate["t"] = useCallback((key, ...rest) => t(key.replace(/\./g, ""), ...rest), [t]);

  return { t: tUtenPunktum, lang, ts };
};
