import { BsExclamationCircle } from "@biblioteksentralen/icons";
import { colors, HStack, Text } from "@biblioteksentralen/react";
import { useTranslation } from "../../utils/hooks/useTranslation";

export const ErrorComponent = () => {
  const { t } = useTranslation();

  return (
    <HStack
      fontWeight="semibold"
      color={colors.statusRed}
      background={colors.alertRed}
      borderRadius="0.25rem"
      padding="0.5rem 1rem"
      gap="0.5rem"
    >
      <BsExclamationCircle />
      <Text>{t("Ops, noe gikk galt")}</Text>
    </HStack>
  );
};
