import { Role } from "@sanity/types";
import useSWR from "swr";

import { clientWithCredidentials } from "../../utils/sanity/client";

interface CurrentSanityUser {
  id: string;
  name: string;
  email: string;
  profileImage: string | null;
  role: string;
  roles?: Role[];
}

export function useSanityAuth() {
  const response = useSWR<CurrentSanityUser>("sanityAuthStatus", () => clientWithCredidentials.users.getById("me"));

  // Source: https://www.sanity.io/docs/js-client#clientauth-is-removed-replace-with-clientrequest
  const logout = async () => {
    await clientWithCredidentials.request<void>({ uri: "/auth/logout", method: "POST" });
    response.mutate();
  };

  return {
    ...response.data,
    isAuthenticated: !!response.data?.id,
    logOut: logout,
    revalidate: response.mutate,
    loading: !response.data && !response.error,
    error: response.error,
  };
}
