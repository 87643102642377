/* eslint-disable i18next/no-literal-string */
import { BiblioteksentralenLogoWithName } from "@biblioteksentralen/icons";
import { Box, BoxProps, Container, Flex, Link, Text } from "@biblioteksentralen/react";

function SplashPageFooter({
  innerContainerMaxWidth = "container.md",
  ...props
}: BoxProps & { innerContainerMaxWidth?: string }) {
  return (
    <Box as="footer" padding="4rem 1rem 3rem" fontSize="xs" {...props}>
      <Container maxW={innerContainerMaxWidth}>
        <Flex justifyContent="space-between" alignItems="flex-end" flexWrap="wrap" gap="1rem">
          <Box>
            <Text>
              <b>Libry Content</b> er utviklet av <Link href="https://www.bibsent.no">Biblioteksentralen</Link>
            </Text>
            <Text>Biblioteksentralen SA, Pb. 24 Alnabru, 0614 OSLO | Org.nr: 910 568 183</Text>
          </Box>
          <Link href="https://www.bibsent.no" _hover={{ color: "#555" }}>
            <BiblioteksentralenLogoWithName aria-label="Biblioteksentralen" height="3em" />
          </Link>
        </Flex>
      </Container>
    </Box>
  );
}

export default SplashPageFooter;
