"use client";

import { useEffect, useState } from "react";

// https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
export const getIsInsideAnIframe = (): boolean | undefined => {
  if (typeof window === "undefined") return undefined;
  try {
    return window.self !== window.top;
  } catch (e) {
    return undefined;
  }
};

export const useIsInsideAnIframe = (): { loading: false; isInside: boolean } => {
  const [isInside, setIsInside] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsInside(getIsInsideAnIframe());
    getIsInsideAnIframe() !== undefined && setLoading(false);
  }, []);

  return { isInside, loading };
};
